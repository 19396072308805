import { computed, Injectable, signal } from '@angular/core';
import { Nullable } from '@models/nullable.model';
import { DynamicObject } from '@models/task.model';

export interface Snackbar {
  message: string;
  title?: string;
  messageParams?: DynamicObject<Nullable<string | number>>;
  duration?: number;
  color?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private _snackbar = signal<Nullable<Snackbar>>(null);

  snackbar = computed(() => this._snackbar());

  add(snackbar: Snackbar): void {
    this._snackbar.set(snackbar);
  }

  reset(): void {
    this._snackbar.set(null);
  }
}
